export default function sendGaEvent(eventLabel, nonInteraction = false, eventAction = 'click') {
  if (window.ga) {
    window.ga('send', {
      hitType: 'event',
      eventCategory: window.location.pathname,
      eventAction,
      eventLabel,
      nonInteraction
    });
  }
}
