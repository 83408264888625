import closestParent from './closestParent';
import sendGaEvent from './sendGaEvent';

// Listen for GA click events
document.addEventListener('DOMContentLoaded', () => {
  // function to send the GA event
  const handleGaEvent = event => {
    const element = closestParent(event.target, '[data-event-label]', true);
    if (element) {
      const eventLabel = element
        .getAttribute('data-event-label')
        .trim()
        .toLowerCase();

      sendGaEvent(eventLabel);
    }
  };
  document.addEventListener('click', handleGaEvent, false);
});

// Function for the dropdown menu on mobile
$('.header__menu-button').on('click', function() {
  $('.header').toggleClass('mobile-open');
  $(this).toggleClass('header__menu-button--active');
  $('nav').slideToggle();
});

// Function for the contact us button on the mobile menu
$('#contact-btn').on('click', function() {
  document.querySelector('.contact').scrollIntoView({ behavior: 'smooth' });

  if ($('.header').hasClass('mobile-open')) {
    $('.header').toggleClass('mobile-open');
    $('.header__menu-button').toggleClass('header__menu-button--active');
    $('nav').slideToggle();
  }
});

// Scroll button for 'blue Sky opttion to 'whats included' section
$('.hero-packages-btn').on('click', function() {
  document.querySelector('.package-details').scrollIntoView({ behavior: 'smooth' });
});

// Scroll to button anchor for the addons component to the contact component
$('.addon-contact-btn').on('click', function() {
  document.querySelector('.contact').scrollIntoView({ behavior: 'smooth' });
});

function closeModal(uid) {
  $('.modal').fadeOut();
  const videoWrapper = $(`.videoWrapper[data-uid|='${uid}']`);
  const videoElem = $(`.videoWrapper[data-uid|='${uid}'] iframe`);
  // timer to allow modal to fade before re-adding
  setTimeout(() => {
    videoWrapper.empty();
    videoWrapper.append(videoElem);
  }, 600);
}

function watchForClose() {
  $('.modal').on('click', function(event) {
    const uid = event.currentTarget.getAttribute('data-uid')
    closeModal(uid);
  })
}

// Open the modal on clicking the button on the included component
$('.included-modal-btn').on('click', function() {
  $('.modal').fadeIn();
  watchForClose();
});

// Close the modal
$('.modal__close-btn').on('click', function(event) {
  const uid = event.currentTarget.getAttribute('data-uid')
  closeModal(uid);
});

// Function for the 'whats included' toggle list
$('.toggle-btn').click(function() {
  var included_id = $(this).attr('data-tab');

  $('.included-modal-btn').attr('data-video-name', included_id);
  $('.included-modal-btn').attr('data-event-label', `${included_id} example`);

  $('.toggle-btn').removeClass('current');
  $('.toggle-btn').attr('disabled', false);
  $('.included__tab-content').removeClass('current');

  $(this).addClass('current');
  $(this).attr('disabled', true);
  $('#' + included_id).addClass('current');
});

// Scroll to button anchor for the hero component to the packages component
$('.package-details__card-footer--blue').on('click', function() {
  document.querySelector('.included__title').scrollIntoView({ behavior: 'smooth' });

  var included_id = 'blue-sky';

  $('.included-modal-btn').attr('data-video-name', included_id);
  $('.included-modal-btn').attr('data-event-label', `${included_id} example`);

  $('.toggle-btn').removeClass('current');
  $('.included__tab-content').removeClass('current');

  $('.included__toggle-btn--centre').addClass('current');
  $('#' + included_id).addClass('current');
});

$('.package-details__card-footer--yellow').on('click', function() {
  document.querySelector('.included__title').scrollIntoView({ behavior: 'smooth' });

  var included_id = 'clear-sky';

  $('.included-modal-btn').attr('data-video-name', included_id);
  $('.included-modal-btn').attr('data-event-label', `${included_id} example`);

  $('.toggle-btn').removeClass('current');
  $('.included__tab-content').removeClass('current');

  $('.included__toggle-btn--right').addClass('current');
  $('#' + included_id).addClass('current');
});

$('.package-details__card-footer--green').on('click', function() {
  document.querySelector('.included__title').scrollIntoView({behavior: 'smooth'});

  var included_id = 'fair-sky';

  $('.included-modal-btn').attr('data-video-name', included_id);
  $('.included-modal-btn').attr('data-event-label', `${included_id} example`);

  $('.toggle-btn').removeClass('current');
  $('.included__tab-content').removeClass('current');

  $('.included__toggle-btn--left').addClass('current');
  $('#' + included_id).addClass('current');
});
